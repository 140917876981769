<template>
  <div class="statistics-box">
    <div class="statistics-box-left">
      <div ref="alarn-img-box">
        <img src="../../../../assets/images/bigScreen/mechanical/yc-num.png"
          :style="{height: boxH * 0.7 + 'px'}"
          alt=""
          srcset="">
        <div>
          <div class="statistics-box-left-num">{{ option.todayAlarmCount || 0 }}</div>
          <div>异常总数</div>
        </div>
      </div>
      <div>
        <img src="../../../../assets/images/bigScreen/mechanical/yc-jx.png"
          :style="{height: boxH * 0.67+ 'px'}"
          alt=""
          srcset="">
        <div>
          <div class="statistics-box-left-num">{{ option.alarmVehicleCount || 0 }}</div>
          <div>异常机械</div>
        </div>
      </div>
    </div>
    <div class="statistics-box-right">
      <div v-for="item,index in option.top3" :key="index">
        <div>
          <div>
            <div class="statistics-box-right-img">
              {{ index + 1 }}
            </div>
            {{ item.alarmTypeName }}
          </div>
          <span class="statistics-box-right-num">{{ item.alarmTypeCount }}</span>
        </div>
      </div>
      <!-- <div>
        <div>
          <div>
            <div class="statistics-box-right-img">
              1
            </div>
            围栏
          </div>
          <span class="statistics-box-right-num">22</span>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div class="statistics-box-right-img">
              1
            </div>
            围栏
          </div>
          <span class="statistics-box-right-num">22</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
   data() {
      return {
         boxH: 0
      }
   },
   props:{
      option: {
         type: Object,
         default: ()=>{}
      }
   },
   mounted() {
      this.$nextTick(()=>{
         this.boxH = this.$refs['alarn-img-box'].offsetHeight;
      })
   }
}
</script>

<style lang="scss" scoped>
.statistics-box {
  height: 100%;
  padding: 5%;
  display: flex;
  > div {
    flex: 1;
  }
  &-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 4%;
    > div {
      height: 45%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: 0.15rem;
      color: #fefefe;
      background-image: url('../../../../assets/images/bigScreen/mechanical/alarn-num-box.png');
      background-size: 100% 100%;
      // img {
      //    margin-left: 4%;
      // }
    }
    &-num {
      font-family: 'ZhanKuQingKeHuangYouTi';
      font-size: 0.25rem;
      color: #cb2b00;
      margin-bottom: 0.2rem;
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div {
      height: 33%;
      display: flex;
      align-items: flex-end;
      width: 100%;
      padding-bottom: 6px;
      background-image: url('../../../../assets/images/bigScreen/mechanical/alarn-dd.png');
      background-size: 100%;
      background-position: bottom;
      background-repeat: no-repeat;
      > div {
        display: flex;
        justify-content: space-between;
        font-size: 0.15rem;
        width: 100%;
        color: #fff;
        padding-right: 0.4rem;
        padding-left: 0.2rem;
        > div {
          display: flex;
          align-items: flex-end;
        }
      }
    }
    &-img {
      width: 18px;
      height: 18px;
      line-height: 18px;
      text-align: center;
      font-family: 'ZhanKuQingKeHuangYouTi';
      margin-right: 8px;
      background-image: url('../../../../assets/images/bigScreen/mechanical/alarn-ld.png');
      background-size: 100% 100%;
    }
    &-num {
      font-family: 'ZhanKuQingKeHuangYouTi';
      font-size: 0.17rem;
      color: #ffffff;
    }
  }
}
</style>