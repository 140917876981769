<template>
  <div class="list-box"
    ref="list-box">
    <div :style="{height: boxH+'px'}"
      style="overflow: auto;"  ref="queue" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
      <div
        :style="{transition:'.8s'}">
        <!-- transform: `translateY(-${lineUpIndex * itemW}px)`, -->
        <div class="item-box"
          v-for="item,index in list"
          :key="index"
          ref="bbbb-box"
          :class="{ 'current-box': index == nowIndex }"
          @click="handleChangIndex(index)">
          <div class="item-box-name">
            <img :src="item.devicePic
									? item.devicePic
									: `https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/${
											carImgList.includes(item.productType + item.machineryEquipmentType)
												? item.productType + item.machineryEquipmentType
												: carImgList.includes(item.productType)
												? item.productType
												: 'excavating_machinery'
									  }.png`"
              alt=""
              srcset="">
            <div class="item-box-name-text">
              <div class="item-box-name-title">{{ item.plate }}</div>
              <div>{{ item.machineryEquipmentType }} {{ item.vehicleBrand }}</div>
            </div>
          </div>
          <div class="item-box-xm">{{ item.projectName }}</div>
          <div class="item-box-xh">
            <div>工时：{{ item.inSumTimeHour }} 小时</div>
            <div style="margin-right:10%">油耗：<span class="item-box-xh-num">{{ item.inSumOil }}</span> L</div>
          </div>
          <div class="item-box-countdown" v-if="index == nowIndex">{{ currentNum }}s</div>
        </div>
        <div class="item-box"
          v-if="!list || !list.length"
          ref="bbbb-box"
          style="opacity: 0;">
          <div class="item-box-name">
            <img src="https://gimg3.baidu.com/search/src=http%3A%2F%2Fpics5.baidu.com%2Ffeed%2Fca1349540923dd54d075a95958cdebd29d8248c9.jpeg%40f_auto%3Ftoken%3D956b4ce44a1d2354eb35c278225477e1&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=f360,240&n=0&g=0n&q=75&fmt=auto?sec=1687366800&t=55b39378e54ab98993313516aa3a5366"
              alt=""
              srcset="">
            <div class="item-box-name-text">
              <div class="item-box-name-title">22</div>
              <div>33</div>
            </div>
          </div>
          <div class="item-box-xm">22</div>
          <div class="item-box-xh">
            <div>工时：33 小时</div>
            <div style="margin-right:10%">油耗：<span class="item-box-xh-num">22</span> L</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      default: () => { }
    }
  },
  watch: {
    option: {
      handler(v) {
        this.timer ? this.clearPolling() : '';
        if (v && v.vehicleListUrl && v.vehicleListUrl.length) {
         //  this.$emit('changeCountdownNum', this.changeCountdownMaxNum);
         this.currentNum = this.changeCountdownMaxNum;
          this.list = v.vehicleListUrl;
          this.lineUpIndex = 0;
          this.nowIndex = 0;
          this.$emit('changeVehicle', this.option.vehicleListUrl[this.nowIndex]);
          this.boxH = 0;
          this.$nextTick(() => {
            this.boxH = this.$refs['list-box'].offsetHeight * 0.965;
            this.itemW = (this.$refs['bbbb-box'][0] || this.$refs['bbbb-box']).offsetHeight + 12;
          });
          this.handlePolling();
        }
      }
    },
    lineUpIndex: {
      handler(v) {
         let H = 0;
         for (let index = 0; index < v; index++) {
           let num = window.getComputedStyle(this.$refs['bbbb-box'][index]).height.split('px');
           H += Number(num[0]) + 12;
         }
         this.$refs['queue'].scrollTop = H;
      }
    }
  },
  data() {
    return {
      list: [],
      boxH: 0,
      lineUpIndex: 0,
      nowIndex: 0,
      itemW: 0,
      timer: null,
      carImgList: [
        'aerial_work_machinery1',
        'aerial_work_machinery2',
        'compaction_machinery',
        'excavating_machinery',
        'excavating_machinery1',
        'hoisting_machinery',
        'hoisting_machinery3',
        'mining_machinery',
        'piling_machinery1',
        'shovel_transport_machinery0',
        'shovel_transport_machinery1',
        'shovel_transport_machinery3',
        'industrial_vehicles1',
					'agriculture_mechanics1',
					'concrete_machinery3',
					'shovel_transport_machinery9',
          'diao_guan_ji0',
          'yi_dong_dian_zhan0',
          'han_jie_gong_zuo_zhan0',
          'zhong_pin_jia_re_che0',
          'new_energy_vehicles0',
      ],
      changeCountdownMaxNum: 20,
      currentNum: 0
    };
  },
  destroyed() {
    clearInterval(this.timer); //销毁定时器
  },
  deactivated() {
    clearInterval(this.timer); //销毁定时器
  },
  mounted() {
    this.$nextTick(() => {
      this.boxH = this.$refs['list-box'].offsetHeight * 0.965;
      this.itemW = (this.$refs['bbbb-box'][0] || this.$refs['bbbb-box']).offsetHeight + 12;
    });
  },
  methods: {
    clearPolling() {
      clearInterval(this.timer);
      this.timer = null;
    },
    handlePolling(type) {
      if (this.timer) {
         this.clearPolling();
         this.handlePolling();
      }
      let num = type && this.currentNum ? this.currentNum : this.changeCountdownMaxNum;
      this.timer = setInterval(async () => {
        num -= 1;
        if (num <= 0) {
          if (this.nowIndex < this.list.length - 1) {
            this.nowIndex += 1;
            (this.itemW * (this.list.length - this.lineUpIndex)) - 12 > this.boxH ? this.lineUpIndex = this.nowIndex : '';
          } else {
            this.nowIndex = 0;
            this.lineUpIndex = this.nowIndex;
          }
          num = this.changeCountdownMaxNum;
          this.$emit('changeVehicle', this.option.vehicleListUrl[this.nowIndex]);
        }
      //   this.$emit('changeCountdownNum', num);
         this.currentNum = num;
      }, 1000 * 1);
      // this.timer = setInterval(async () => {
      //   if (this.nowIndex < this.list.length - 1) {
      //     this.nowIndex += 1;
      //     (this.itemW * (this.list.length - this.nowIndex)) - 12 > this.boxH ? this.lineUpIndex = this.nowIndex : '';
      //   } else {
      //     this.nowIndex = 0;
      //     this.lineUpIndex = this.nowIndex;
      //   }
      //   this.$emit('changeVehicle', this.option.vehicleListUrl[this.nowIndex]);
      // }, 1000 * 3);
    },
    handleChangIndex(index) {
      this.nowIndex = index;
      if (this.timer) {
         this.clearPolling();
         this.handlePolling();
      }
      this.$emit('changeVehicle', this.option.vehicleListUrl[this.nowIndex]);
    },
    handleMouseEnter() {
      this.clearPolling();
    },
    handleMouseLeave() {
      if (this.$parent.isPolling) {
      this.handlePolling(true);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.list-box {
  height: 96.5%;
  padding: 4%;
  .item-box {
    padding: 10px 0.16rem;
    background-color: #103561;
    margin-bottom: 12px;
    // transition: all 0.5s;
    overflow: hidden;
    position: relative;
    &-name {
      display: flex;
      > img {
        width: 56px;
        height: 56px;
        margin-right: 0.16rem;
      }
      &-text {
        font-size: 0.17rem;
        color: #feffff;
      }
      &-title {
        font-size: 0.17rem;
        font-weight: bold;
        color: #feffff;
        margin-bottom: 16px;
      }
    }
    &-xm {
      font-size: 0.17rem;
      color: #feffff;
      margin-bottom: 0.12rem;
      margin-top: 0.12rem;
    }
    &-xh {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-size: 0.17rem;
      color: #feffff;
      &-num {
        font-family: 'ZhanKuQingKeHuangYouTi';
        color: #fffd37;
        font-size: 0.25rem;
      }
    }
    &-countdown {
      position: absolute;
      top: 10px;
      right: 0.16rem;
      width: 0.40rem;
      height: 0.40rem;
      line-height: 0.40rem;
      text-align: center;
      background-color: rgba(249,249,249,0.38);
      color: #fff;
      font-size: 0.16rem;
      border-radius: 50%;
    }
  }
  .current-box {
    box-shadow: inset 0px 0px 30px 2px rgba(1,251,246, 0.8);
  }
}
</style>