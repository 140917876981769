var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"early-box",staticClass:"early-box"},[_c('vue-seamless-scroll',{style:({ width: '100%',height: _vm.boxH+'px' }),attrs:{"data":_vm.dataList,"class-option":_vm.defaultOption}},_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index,staticClass:"area"},[_c('div',{staticClass:"area-head"},[_c('div',{staticStyle:{"display":"flex"}},[_c('img',{staticClass:"area-head-icon",attrs:{"src":require("../../../../assets/images/bigScreen/mechanical/alarn-icon.png"),"alt":"","srcset":""}}),_c('span',{staticClass:"area-head-title"},[_vm._v(_vm._s(item.alarmName))])]),_c('div',{staticClass:"area-head-time"},[_vm._v(" "+_vm._s(item.beginTime)+" ")])]),_c('div',{staticClass:"area-car"},[_c('img',{staticClass:"area-car-img",attrs:{"src":item.imageUrl||item.devicePic
									? item.devicePic
									: `https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/BigScreenDisplay/${
											_vm.carImgList.includes(item.vehicleType + item.vehicleClass)
												? item.vehicleType + item.vehicleClass
												: _vm.carImgList.includes(item.vehicleType)
												? item.vehicleType
												: 'excavating_machinery'
									  }.png`,"alt":"","srcset":""}}),_c('div',[_c('div',{staticClass:"area-car-name"},[_vm._v(_vm._s(item.groupName)+"-"+_vm._s(item.plate))]),_c('div',{staticClass:"area-car-alarn"},[_vm._v(_vm._s('当前机械设备报警，请尽快检查' || item.terminalTypeName))])])]),_c('div',{staticClass:"area-surplus"},[_vm._v("项目："+_vm._s(item.projectName))]),_c('div',{staticClass:"area-surplus"},[_vm._v("地点："+_vm._s(item.address))])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }