<template>
  <div class="early-box"
    ref="early-box">
    <vue-seamless-scroll :data="dataList"
      :style="{ width: '100%',height: boxH+'px' }"
      :class-option="defaultOption">
      <div class="area"
        v-for="(item, index) in dataList"
        :key="index">
        <div class="area-head">
          <div style="display: flex;">
            <img class="area-head-icon"
              src="../../../../assets/images/bigScreen/mechanical/alarn-icon.png"
              alt=""
              srcset="">
            <span class="area-head-title">{{ item.alarmName }}</span>
          </div>
          <div class="area-head-time">
            {{ item.beginTime }}
          </div>
        </div>
        <div class="area-car">
          <img class="area-car-img"
            :src="item.imageUrl||item.devicePic
									? item.devicePic
									: `https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/BigScreenDisplay/${
											carImgList.includes(item.vehicleType + item.vehicleClass)
												? item.vehicleType + item.vehicleClass
												: carImgList.includes(item.vehicleType)
												? item.vehicleType
												: 'excavating_machinery'
									  }.png`"
            alt=""
            srcset="">
          <div>
            <div class="area-car-name">{{ item.groupName }}-{{ item.plate }}</div>
            <div class="area-car-alarn">{{ '当前机械设备报警，请尽快检查' || item.terminalTypeName }}</div>
          </div>
        </div>
        <div class="area-surplus">项目：{{ item.projectName }}</div>
        <div class="area-surplus">地点：{{ item.address }}</div>
      </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll';
export default {
  components: {
    vueSeamlessScroll
  },
  props: {
    options: {
      type: Array,
      default: () => []
    }
  },
  created() {
    this.$nextTick(() => {
      this.boxH = this.$refs['early-box'].offsetHeight;
    });
  },
  watch: {
    options: {
      handler(v) {
        this.dataList = this.options.map(val=>{
         return {
            ...val,
            imageUrl: val.imgAttachment && val.imgAttachment.length ? val.imgAttachment[0] : ''
         }
        })
      // .acAndVc.map(val => ({
      //     progressValue: val.vcRatio * 100,
      //     label: val.alarmName,
      //     value: val.ac
      //   }));
      }
    }
  },
  data() {
    return {
      dataList: [],
      defaultOption: {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      },
      proTextStyle: { color: '#F7981D', fontSize: '0.26rem' },
      outerStyle: { border: '1px solid rgba(128, 235, 255, .5)', borderRadius: 4 + 'px', height: 16 + 'px' },
      boxH: 0,
      carImgList: [
        'excavating_machinery',
        'agriculture_mechanics',
        'shovel_transport_machinery',
        'compaction_machinery',
        'road_building_machinery',
        'hoisting_machinery',
        'aerial_work_machinery',
        'piling_machinery',
        'concrete_machinery',
        'industrial_vehicles',
        'mining_machinery',
        'port_machinery',
        'auxiliary_tooling',
        'dynamic_system',
        'bridge_machinery'
      ],
    };
  }
}
</script>

<style lang="scss" scoped>
.early-box {
   height: 94%;
    padding: 3%;
    overflow: hidden;
    margin: 3%;
  .area {
    width: 100%;
    padding: 12px 18px;
    margin-bottom: 16px;
    background-image: url(../../../../assets/images/bigScreen/mechanical/warning.png);
    background-size: 100% 100%;
    &-head {
      display: flex;
      justify-content: space-between;
      &-icon {
        width: 0.2rem;
        margin-right: 0.08rem;
      }
      &-title {
        font-size: 0.17rem;
        font-weight: bold;
        color: #ffffff;
      }
      &-time {
        font-size: 0.13rem;
        color: #feffff;
      }
    }
    &-car {
      display: flex;
      margin-top: 0.18rem;
      &-img {
        width: 0.46rem;
        height: 0.46rem;
        margin-right: 0.12rem;
      }
      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 0.15rem;
      }
      &-name {
        font-weight: bold;
        color: #feffff;
      }
      &-alarn {
        color: #ff4200;
      }
    }
    &-surplus {
      margin-top: 0.12rem;
      color: #FEFFFF;
        font-size: 0.15rem;
    }
  }
}
</style>