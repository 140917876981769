<template>
  <div class="sb-table">
    <!-- //列头 -->
    <div class="table-header">
      <div v-for="(a,c) in visualData" :key="c">{{a.label}}</div>
    </div>

    <div class="ScrollDiv" ref="ScrollDiv">
      <el-empty v-if="!Datas.length" style="height: 100%" :image-size="25" class="emptyCls"></el-empty>
      <div
        v-else
        class="ScrollTable"
        style="position:absolute;width:100%"
        ref="infoDiv"
        :class="{divScroll:iScorll == true}"
      >
        <vue-seamless-scroll :data="dataListclo" :class-option="defaultOption">
          <div
            class="table-item"
            v-for="(item,index) in dataListclo"
            :key="index"
          >
            <div v-for="(a,c) in visualData" :key="c">{{ a.key == 'index' ? index+1 : item[a.key]}}{{a.unit}}</div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>
<script>
//格子内文字自左向右轮播
import textScroll from '../../Scroll';
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  data() {
    return {
      dataListclo: [],
      iBoolean: false,
      itextBoolean: true,

      //用于控制文字自左向右轮播开关
      needToScroll: false,
      //用于轮播开关
      iScorll: false,
      defaultOption: {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    };
  },
  components: {
    textScroll,
    vueSeamlessScroll
  },
  props: { //接收父组件传递来的值
    visualData: {  //表头
      type: Array,
      default: []
    },
    Datas: { //table 数据
      type: Array,
      default: ()=>[]
    },
    Booleans: {  //控制排序图标是否显示
      type: Boolean,
      default: false
    },
    textBoolean: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    Datas: {
      handler(val) {
      // this.dataListclo = [];
      // this.dataListclo.push(this.visualData.map(p => p.label));
      // this.dataListclo.push(...val.map(item => this.visualData.map(col => item[col.key])));
      val && val.length ? this.dataListclo = val.map(item => item) : '';
      //控制排序是否显示
      this.iBoolean = this.Booleans;
      this.itextBoolean = this.textBoolean;
    },
    immediate: true
    }
  },
  methods: {
    textScrollbar() {//用此函数进行轮播控制
      //一行内容高度在calss 那里设置了为父元素的 10% ，所以大于N元素就会超出父元素，从而需要轮播
      this.iScorll = false;
      if (this.dataListclo.length > 6) {
        this.iScorll = true;//开启轮播，在父元素出绑定带有tarnsition 的 class 
      };

      if (this.iScorll) {//开始轮播
        setTimeout(() => {
          this.dataListclo.push(this.dataListclo[1]);//将数组的第二行数据添加到末尾
          this.dataListclo.splice(1, 1);//将数组的第二行进行删除
          this.iScorll = false;//margin-top 为0 的时候取消过度动画，实现无缝轮播
        }, 500);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.sb-table {
  position: relative;
  flex: 1;
  overflow: hidden;
  height: 89%;
  display: flex;
  flex-direction: column;
   padding: 3%;
  .ScrollTable {
    // width: 100%;
    // height: 100%;
    // overflow: auto;
  }
  .table-header {
    display: flex;
    background: linear-gradient(0deg, #035DBB 0%, #032761 100%);
    > div {
      flex: 1;
      color: #007EFF;
      text-align: center;
      font-size: 0.15rem;
      padding: 0.12rem 0;
    }
  }
  .ScrollDiv {
    flex: 1;
    // height: 100%;
    overflow: hidden;
    // overflow: auto;
    position: relative;
  }
  .table-item {
    display: flex;
    position: relative;
      margin-top: 0.1rem;
   background-color: rgba(2, 94, 188, 0.2);
    >div {
      flex: 1;
      color: #fff;
      text-align: center;
      font-size: 0.15rem;
      padding: 0.12rem 0;
    }
  }
  .table-item::after {
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   width: 4px;
   background: #025EBC;
  }
}
// -Q
.scrollText {
  overflow: hidden;
  white-space: nowrap;
}
.st-inner {
  display: inline-block;
}
.st-scrolling .st-section {
  padding: 0 5 px;
}

//向左匀速滚动动画
.st-scrolling {
  animation: scroll 10s linear infinite;
}
.st-scrolling:hover {
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

//这个class 用于控制table向上滚动
.divScroll {
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  margin-top: -29.97px;
}

/** 滚动条美化样式开始 **/
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #3a5bab;
}
/** 滚动条美化样式结束 **/

.emptyCls {
  padding: 0;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 1366px) {
  .sb-table {
    .table-header {
      > label {
        line-height: 0.24rem;
      }
    }
  }
}
</style>
