<template>
  <div class="map-box" ref="map-box">
    <BSJMap ref="mapRef"
      :center="centerLng" :zoom="16"
      @loaded="onMapLoad">
      <!-- 展示地图信息窗体 -->
      <InfoWindow ref="windowObj"
        v-model="infoVisible"
        :lngLat="windowPoint"
        :offset="[0, 25]"
        :width="390"
        :height="310"
        autoMove>
        <div style="height: 210px">
          <div class="head-box">
            <div :class="[mapVehicleData.onLine?'head-box-left': 'head-box-left2']">{{ mapVehicleData.onLine ? '在线' : '离线' }}</div>
            <div class="head-box-centen">
              <img src="../../../../assets/images/bigScreen/mechanical/jbjb.png"
                alt=""
                srcset="">
              <span>警告</span>
            </div>
            <div class="head-box-right">
              <span>日报</span>
              >>
              <i class="el-icon-close"
                @click="handleClose"
                style="color:#fff;cursor: pointer;"></i>
            </div>
          </div>
          <div class="car">
            <img :src="mapVehicleData.devicePic
									? mapVehicleData.devicePic
									: `https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/${
											carImgList.includes(mapVehicleData.productType + mapVehicleData.machineryEquipmentType)
												? mapVehicleData.productType + mapVehicleData.machineryEquipmentType
												: carImgList.includes(mapVehicleData.productType)
												? mapVehicleData.productType
												: 'excavating_machinery'
									  }.png`"
              alt=""
              srcset="">
            <div class="car-text">
              <div class="car-title">{{ mapVehicleData.plate }}</div>
              <div>{{ mapVehicleData.frameNo }}</div>
            </div>
          </div>
          <div class="consume">
            <div>
              <div class="consume-top">
                <img src="../../../../assets/images/bigScreen/mechanical/ybp.png"
                  alt=""
                  class="consume-img"
                  srcset="">
                <span>当前油量</span>
              </div>
              <div>
                <span class="consume-num">{{ mapVehicleData.inSumOil || 0 }}</span>L
              </div>
            </div>
            <div>
              <div class="consume-top">
                <img src="../../../../assets/images/bigScreen/mechanical/sz.png"
                  alt=""
                  class="consume-img"
                  srcset="">
                <span>今日工时</span>
              </div>
              <div>
                <span class="consume-num">{{ mapVehicleData.inSumTimeHour? mapVehicleData.inSumTimeHour[0] || 0 : 0 }}</span>时<span class="consume-num">{{ mapVehicleData.inSumTimeHour? mapVehicleData.inSumTimeHour[1] || 0 : 0 }}</span>分
              </div>
            </div>
            <div>
              <div class="consume-top">
                <img src="../../../../assets/images/bigScreen/mechanical/jz.png"
                  alt=""
                  class="consume-img"
                  srcset="">
                <span>持续{{ mapVehicleData.currentStatus }}</span>
              </div>
              <div>
                <span class="consume-num">{{ mapVehicleData.currentStatusTime? mapVehicleData.currentStatusTime[0] || 0 : 0 }}</span>时<span class="consume-num">{{ mapVehicleData.currentStatusTime? mapVehicleData.currentStatusTime[1] || 0 : 0 }}</span>分
              </div>
            </div>
          </div>
          <div class="address">地点：{{ mapVehicleData.address }}</div>
        </div>
      </InfoWindow>
    </BSJMap>
    <!-- <div class="countdown">
      <img src="../../../../assets/images/bigScreen/mechanical/countdown-icon.png"
        style="width:16px;height:16px;margin-right:2px"
        alt=""
        srcset="">
      {{ countdownNum }}s 后将自动切换到下个项目
    </div> -->
    <div class="statistics-box">
      <span style="color: #62FFA8;">作业：{{ statisticsData.jobCount }}台</span>
      <div style="width:20px"></div>
      <span style="color: #FFFD37;">怠速：{{ statisticsData.idleCount }}台</span>
      <div style="width:20px"></div>
      <span style="color: #58B6FF;">离线：{{ statisticsData.offlineCount }}台</span>
      <div style="width:20px"></div>
      <span style="color: #FFFFFF;">最高离线天数：{{ statisticsData.offlineDateTime }}天</span>
    </div>
  </div>
</template>

<script>
import BSJMap from '@/components/AMap';
import InfoWindow from '@/views/BigScreenDisplay/components/mechanical/VehicleWindow';
import { getTerminalMonitor, MonitorPositionInfo } from "@/api/BigScreenDisplay";
import { getDwellTime3 } from "@/utils/formatDate";

export default {
  components: {
    BSJMap,
    InfoWindow
  },
  props: {
    vehicleData: {
      type: Object,
      default: () => { }
    },
    countdownNum: {
      type: [String, Number],
      default: 0
    }
  },
  watch: {
    vehicleData: {
      handler(v) {
        if (v && v.vehicleId) {
         this.$nextTick(()=>{
            this.offsetX = 0.000015224334600760455 * (this.$refs['map-box'].offsetHeight / 4);
            this.initVehicle();
            if (this.mapObj && v.lat && v.lon) {
               this.mapObj.setCenter([this.vehicleData.lon, this.vehicleData.lat + this.offsetX]);
               this.windowPoint = [Number(this.vehicleData.lon), Number(this.vehicleData.lat)];
            }
         })
        }
      },
      deep: true
    }
  },
  data() {
    return {
      centerLng: null,
      mapObj: null,
      infoVisible: true,
      windowPoint: [116.401337, 39.907886],
      statisticsData: {},
      mapVehicleData: {},
      carImgList: [
        'aerial_work_machinery1',
        'aerial_work_machinery2',
        'compaction_machinery',
        'excavating_machinery',
        'excavating_machinery1',
        'hoisting_machinery',
        'hoisting_machinery3',
        'mining_machinery',
        'piling_machinery1',
        'shovel_transport_machinery0',
        'shovel_transport_machinery1',
        'shovel_transport_machinery3',
        'industrial_vehicles1',
					'agriculture_mechanics1',
					'concrete_machinery3',
					'shovel_transport_machinery9',
          'diao_guan_ji0',
          'yi_dong_dian_zhan0',
          'han_jie_gong_zuo_zhan0',
          'zhong_pin_jia_re_che0',
          'new_energy_vehicles0',
      ],
      offsetX: 0
    };
  },
  created() {
    getTerminalMonitor({ userId: this.$store.state.userInfo.userId }).then(res => {
      this.statisticsData = res.obj;
    });
    this.$nextTick(() => {
      this.$refs.mapRef.panTo();
    });
  },
  methods: {
    onMapLoad(map) {
      this.mapObj = map;
      this.mapObj.setMapStyle('amap://styles/30b04e25b1e43697d8e46ca5eb19f169');
      this.offsetX = 0.000015224334600760455 * (this.$refs['map-box'].offsetHeight / 4);
      if (this.vehicleData && this.vehicleData.lat && this.vehicleData.lon) {
        this.mapObj.setCenter([this.vehicleData.lon, this.vehicleData.lat + this.offsetX]);
        this.windowPoint = [Number(this.vehicleData.lon), Number(this.vehicleData.lat)];
      }else {
        this.mapObj.setCenter([this.windowPoint[0], this.windowPoint[1] + this.offsetX]);
      }
    },
    handleClose() {
      this.infoVisible = false;
    },
    initVehicle() {
      MonitorPositionInfo({
        vehicleId: this.vehicleData.vehicleId
      }).then(res => {
        res.obj.currentStatusTime = getDwellTime3(res.obj.currentStatusTime * 1000);
        res.obj.inSumTimeHour = getDwellTime3(res.obj.inSumTimeHour * 1000);
        this.mapVehicleData = { ...this.vehicleData, ...res.obj };
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.map-box {
  height: 100%;
  width: 100%;
  padding: 0.5%;
  position: relative;
  .head-box {
    display: flex;
    justify-content: space-between;
    &-left {
      padding: 1px 5px;
      font-size: 14px;
      color: #62ffa8;
      background: rgba(98, 255, 168, 0.3);
      border: 1px solid #62ffa8;
    }
    &-left2 {
      padding: 1px 5px;
      font-size: 14px;
      color: red;
      background: rgba(255, 66, 0, 0.3);
      border: 1px solid red;
    }
    &-centen {
      font-size: 16px;
      font-weight: bold;
      color: #0d274a;
      img {
        width: 16px;
        height: 15px;
      }
    }
    &-right {
      font-size: 14px;
      color: #01fbf6;
    }
  }
  .car {
    display: flex;
    margin-top: 12px;
    > img {
      width: 44px;
      height: 44px;
      margin-right: 0.16rem;
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      // padding: 8px 0;
    }
    &-text {
      font-size: 0.17rem;
      color: #feffff;
    }
    &-title {
      font-size: 0.17rem;
      font-weight: bold;
      color: #feffff;
      //   margin-bottom: 16px;
    }
  }
  .consume {
    display: flex;
    margin-top: 4px;
    margin-bottom: 4px;
    > div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 80px;
      padding: 16px;
      font-size: 0.15rem;
      color: #feffff;
      background-image: url(../../../../assets/images/bigScreen/mechanical/car-text.png);
      background-size: 100% 100%;
      text-align: center;
    }
    &-top {
      display: flex;
      justify-content: space-between;
    }
    &-img {
      height: 0.18rem;
    }
    &-num {
      font-family: 'ZhanKuQingKeHuangYouTi';
      color: #fffd37;
      font-size: 0.25rem;
    }
  }
  .address {
    font-size: 0.15rem;
    color: #feffff;
  }
  .statistics-box {
    position: absolute;
    bottom: 25px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    // width: 2.68rem;
    height: 0.5rem;
    font-size: 0.16rem;
    color: #96f5f8;
    padding: 0 0.4rem;
    background-image: url('../../../../assets/images/bigScreen/home/mapzs.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: 999;
  }
}
.countdown {
  position: absolute;
  display: flex;
  align-items: center;
  right: 80px;
  top: 16px;
  color: #feffff;
  font-size: 14px;
  padding: 5px 14px;
  background: rgba(13, 105, 196, 0.6);
  border-radius: 12px;
  z-index: 999;
}
</style>