var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"list-box",staticClass:"list-box"},[_c('div',{ref:"queue",staticStyle:{"overflow":"auto"},style:({height: _vm.boxH+'px'}),on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}},[_c('div',{style:({transition:'.8s'})},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,ref:"bbbb-box",refInFor:true,staticClass:"item-box",class:{ 'current-box': index == _vm.nowIndex },on:{"click":function($event){return _vm.handleChangIndex(index)}}},[_c('div',{staticClass:"item-box-name"},[_c('img',{attrs:{"src":item.devicePic
									? item.devicePic
									: `https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/${
											_vm.carImgList.includes(item.productType + item.machineryEquipmentType)
												? item.productType + item.machineryEquipmentType
												: _vm.carImgList.includes(item.productType)
												? item.productType
												: 'excavating_machinery'
									  }.png`,"alt":"","srcset":""}}),_c('div',{staticClass:"item-box-name-text"},[_c('div',{staticClass:"item-box-name-title"},[_vm._v(_vm._s(item.plate))]),_c('div',[_vm._v(_vm._s(item.machineryEquipmentType)+" "+_vm._s(item.vehicleBrand))])])]),_c('div',{staticClass:"item-box-xm"},[_vm._v(_vm._s(item.projectName))]),_c('div',{staticClass:"item-box-xh"},[_c('div',[_vm._v("工时："+_vm._s(item.inSumTimeHour)+" 小时")]),_c('div',{staticStyle:{"margin-right":"10%"}},[_vm._v("油耗："),_c('span',{staticClass:"item-box-xh-num"},[_vm._v(_vm._s(item.inSumOil))]),_vm._v(" L")])]),(index == _vm.nowIndex)?_c('div',{staticClass:"item-box-countdown"},[_vm._v(_vm._s(_vm.currentNum)+"s")]):_vm._e()])}),(!_vm.list || !_vm.list.length)?_c('div',{ref:"bbbb-box",staticClass:"item-box",staticStyle:{"opacity":"0"}},[_vm._m(0),_c('div',{staticClass:"item-box-xm"},[_vm._v("22")]),_vm._m(1)]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-box-name"},[_c('img',{attrs:{"src":"https://gimg3.baidu.com/search/src=http%3A%2F%2Fpics5.baidu.com%2Ffeed%2Fca1349540923dd54d075a95958cdebd29d8248c9.jpeg%40f_auto%3Ftoken%3D956b4ce44a1d2354eb35c278225477e1&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=f360,240&n=0&g=0n&q=75&fmt=auto?sec=1687366800&t=55b39378e54ab98993313516aa3a5366","alt":"","srcset":""}}),_c('div',{staticClass:"item-box-name-text"},[_c('div',{staticClass:"item-box-name-title"},[_vm._v("22")]),_c('div',[_vm._v("33")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-box-xh"},[_c('div',[_vm._v("工时：33 小时")]),_c('div',{staticStyle:{"margin-right":"10%"}},[_vm._v("油耗："),_c('span',{staticClass:"item-box-xh-num"},[_vm._v("22")]),_vm._v(" L")])])
}]

export { render, staticRenderFns }